import React, { useEffect, useState } from "react";

const FormField = ({ label, id, type, icon }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    localStorage.setItem(id, JSON.stringify(value));
  }, [value]);

  return (
    <div className="form-floating" style={{ marginTop: 10 }}>
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={label}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <label htmlFor={id}><i className={icon}></i> {label}</label>
    </div>
  );
};

export default FormField;
