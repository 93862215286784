export default {
  api: "https://api.sifar-pcl.de/api",
  login: "/login",
  inspectionType: "/inspection_types",
  risk_categories: "/risk_categories",
  risk_sub_categories: "/risk_sub_categories",
  incident_questions: "/incident_questions",
  priorities: "/incident_priorities",
  assignmentTo: "/assignment_tos",
  generateReport: "/generate_report",
  generateReportTemp: "/generate_report_temp",
};
