import {
  faAt,
  faKey,
  faRightToBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Logo from "../../utils/assets/images/logo.png";
import Header from "../../utils/components/Header/Header";
import Endpoints from "../../utils/repository/Endpoints";
import {
  GetRequest,
  NoAuthPostRequest,
} from "../../utils/repository/RequestMaker";
import getMobileOperatingSystem from "../../utils/sharedLogics/getMobileOperatingSystem";
import { addUser, setExpireAt, setLoginAt } from "./loginSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const user = useSelector((state) => state.login.user);
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsSubmitting(true);
    var raw = JSON.stringify({
      email,
      password,
    });
    NoAuthPostRequest(raw, Endpoints.login).then((result) => {
      setIsSubmitting(false);
      localStorage.setItem("token", result.data.token.access_token);
      dispatch(addUser(result.data.user));
      GetRequest(Endpoints.inspectionType).then((res) => {
        localStorage.setItem("inspectionType", JSON.stringify(res.data));
        GetRequest(Endpoints.incident_questions).then((response) => {
          localStorage.setItem(
            "incident_questions",
            JSON.stringify(response.data)
          );
          GetRequest(Endpoints.risk_categories).then((risk_categories) => {
            localStorage.setItem(
              "risk_categories",
              JSON.stringify(risk_categories.data)
            );
            GetRequest(Endpoints.risk_sub_categories).then(
              (risk_sub_categories) => {
                localStorage.setItem(
                  "risk_sub_categories",
                  JSON.stringify(risk_sub_categories.data)
                );
                navigate("/");
              }
            );
          });
        });
      });
    });
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <Header />
      <div
        className={isPortrait ? "container-fliud" : "container"}
        style={{ padding: 10 }}
      >
        <div className="display-4 mb-3">Welcome!</div>
        <FormInput
          type="email"
          id="user_email"
          placeholder="name@example.com"
          label="Email address"
          hint="enter your email address"
          icon={faAt}
          value={email}
          setValue={setEmail}
        />

        <FormInput
          type="password"
          id="user_password"
          placeholder="xxxxx"
          label="Password"
          hint="enter your password"
          icon={faKey}
          value={password}
          setValue={setPassword}
        />
        <div className="d-flex justify-content-end" style={{ marginTop: 20 }}>
          {isSubmitting ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height: 50,
                width: 100,
                transition: "0.3s",
                backgroundColor: "#332885",
                color: "white",
                border: "1px solid #332885",
                borderRadius: 5,
              }}
              id="login_submitting"
            >
              <div
                class="spinner-border text-light"
                role="status"
                //   style={{ height: 50, width: 50 }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <button
              style={{
                height: 50,
                width: 100,
                transition: "0.3s",
                backgroundColor: "#332885",
                color: "white",
                border: "1px solid #332885",
                borderRadius: 5,
              }}
              onClick={onSubmit}
            >
              Login <FontAwesomeIcon icon={faRightToBracket} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const FormInput = ({
  type,
  id,
  placeholder,
  icon,
  hint,
  label,
  value,
  setValue,
}) => {
  return (
    <div className="form-floating mb-3">
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{}}
      />
      <label htmlFor={id}>
        <FontAwesomeIcon icon={icon} /> {label}
      </label>
      <div
        className="d-flex justify-content-end text-muted"
        style={{ fontSize: 12 }}
      >
        {hint}
      </div>
    </div>
  );
};
export default Login;
