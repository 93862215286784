import { combineReducers } from "redux";
import formSlice from "../pages/Form/formSlice";
import loginSlice from "../pages/Login/loginSlice";

const appReducer = combineReducers({
  login: loginSlice,
  form: formSlice,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
