import React, { useEffect, useState } from "react";
import Header from "../../utils/components/Header/Header";
import { useMediaQuery } from "react-responsive";
import SeperatorHeading from "../../utils/components/SeperatorHeading/SeperatorHeading";
import InspectionType from "../../utils/components/InspectionType/InspectionType";
import FormField from "../../utils/components/FormField/FormField";
import TextFieldBig from "../../utils/components/TextFieldBig/TextFieldBig";
import Events from "../../utils/components/Events/Events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsis,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { PostRequestWithTextResponse } from "../../utils/repository/RequestMaker";
import Endpoints from "../../utils/repository/Endpoints";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import CompanyIncident from "../../utils/components/CompanyIncident/CompanyIncident";

const Form = () => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [onTop, setOnTop] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pdf, setPdf] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sendingData, setSendingData] = useState(false);
  const [projectUUID, setProjectUUID] = useState(uuidv4());
  const inspectionType = useSelector((state) => state.form.inspectionType);
  const user = useSelector((state) => state.login.user);
  const [inspectionTypes, setInspectionTypes] = useState([]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      setOnTop(false);
    } else {
      setOnTop(true);
    }
  }

  const onSubmit = () => {
    setSendingData(true);
    const projekt = JSON.parse(localStorage.getItem("projekt"));
    const inspektionsart = JSON.parse(localStorage.getItem("inspektionsart"));
    const auftraggeber = JSON.parse(localStorage.getItem("auftraggeber"));
    const bereich = JSON.parse(localStorage.getItem("bereich"));
    const begehungsdatum = JSON.parse(localStorage.getItem("begehungsdatum"));
    const begehungdurch = JSON.parse(localStorage.getItem("begehungdurch"));
    const teilnehmer = JSON.parse(localStorage.getItem("teilnehmer"));
    const sonstige = JSON.parse(localStorage.getItem("sonstige"));
    const anmerkung = JSON.parse(localStorage.getItem("anmerkung"));
    const email = JSON.parse(localStorage.getItem("email"));

    if (!begehungsdatum) {
      alert("make sure all fields are filled");
      setSendingData(false);
      return;
    }

    let incidents =
      inspectionType === "Bausstellenbegehung"
        ? getEventsIncidents()
        : getCompanyIncidents();

    const formData = {
      inspection_type_id: inspektionsart,
      project_name: projekt,
      inspected_by: begehungdurch,
      participants: teilnehmer,
      description: anmerkung,
      other_info: sonstige,
      inspected_date: properDate(begehungsdatum.split("-").join(".")),
      customer: auftraggeber,
      email: email,
      area: bereich,
      // begehungdurch,
      incidents,
    };
    console.log(formData);
    requestMaker(formData);
  };

  const requestMaker = (formData) => {
    PostRequestWithTextResponse(formData, Endpoints.generateReport).then(
      (result) => {
        setFormSubmitted(true);
        setPdf(result);
        setSendingData(false);
      }
    );
  };

  const savePdf = () => {
    var blob = b64toBlob(pdf, "application/pdf");
    setPdfURL(URL.createObjectURL(blob));
    saveAs(
      blob,
      JSON.parse(localStorage.getItem("projekt")) + "_" + projectUUID
    );
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {
    setInspectionTypes(JSON.parse(localStorage.getItem("inspectionType")));
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  const onMangelClick = () => {
    resetData();
    if (inspectionType === "Bausstellenbegehung") {
      setEventList((eventList) => [
        ...eventList,
        <Events index={eventList.length} />,
      ]);
    } else if (
      inspectionType === "Betriebsbegehung" ||
      inspectionType === "Brandschutzbegehung"
    ) {
      setEventList((eventList) => [
        <CompanyIncident index={eventList.length} />,
      ]);
    } else {
      alert("Select Art der Begehung");
    }
  };

  const getEventsIncidents = () => {
    let incidents = [];
    for (let i = 0; i < eventList.length; i++) {
      if (localStorage.getItem("gefahrdung" + i) !== null) {
        const gefahrdung = JSON.parse(localStorage.getItem("gefahrdung" + i));
        const gefahrdung_unterkategorie = JSON.parse(
          localStorage.getItem("gefahrdung_unterkategorie" + i)
        );
        if (gefahrdung === "" && gefahrdung_unterkategorie === "") {
          alert("Bitte erwähnen Sie die Gefahrdung");
          setSendingData(false);
          return;
        }
        const festgestellter_mangel = JSON.parse(
          localStorage.getItem("festgestellter_mangel" + i)
        );
        const gewerksübergreifend = JSON.parse(
          localStorage.getItem("gewerksübergreifend" + i)
        );
        const ort = JSON.parse(localStorage.getItem("ort" + i));
        const verursachtdurch = JSON.parse(
          localStorage.getItem("verursachtdurch" + i)
        );
        const gewerk = JSON.parse(localStorage.getItem("gewerk" + i));
        const schutzmassnahme = JSON.parse(
          localStorage.getItem("schutzmassnahme" + i)
        );
        const einstufung = JSON.parse(localStorage.getItem("einstufung" + i));
        var photos = JSON.parse(localStorage.getItem("photos" + i));
        if (!photos) {
          photos = [];
        }
        incidents.push({
          risk_category_id: gefahrdung,
          risk_sub_category_id: gefahrdung_unterkategorie,
          deficiency: festgestellter_mangel,
          shared_trade: gewerksübergreifend,
          site: ort,
          caused_by: verursachtdurch,
          trade: gewerk,
          solution: schutzmassnahme,
          status: einstufung,
          info_photos: photos,
        });
      }
    }
    return incidents;
  };

  const getCompanyIncidents = () => {
    let incidents = [];
    let questions = JSON.parse(
      localStorage.getItem("incident_questions")
    ).filter(
      (_q) =>
        _q.inspection_type_id ===
        JSON.parse(localStorage.getItem("inspektionsart"))
    );

    let numberOfQuestions = questions.length;
    for (let j = 0; j < numberOfQuestions; j++) {
      incidents.push({
        question_id: questions[j].id,
        answer: localStorage.getItem("yn_" + j),
        comment:
          localStorage.getItem("yn_comment_" + j) === "null"
            ? ""
            : localStorage.getItem("yn_comment_" + j) || "",
        status: JSON.parse(localStorage.getItem("status_" + j)) || "",
        info_photos: JSON.parse(localStorage.getItem("photos" + j)) || [],
      });
    }
    return incidents;
  };

  const resetData = () => {
    const preStore = {
      anmerkung: localStorage.getItem("anmerkung"),
      auftraggeber: localStorage.getItem("auftraggeber"),
      begehungdurch: localStorage.getItem("begehungdurch"),
      begehungsdatum: localStorage.getItem("begehungsdatum"),
      bereich: localStorage.getItem("bereich"),
      email: localStorage.getItem("email"),
      incident_questions: localStorage.getItem("incident_questions"),
      inspectionType: localStorage.getItem("inspectionType"),
      inspektionsart: localStorage.getItem("inspektionsart"),
      "persist:root": localStorage.getItem("persist:root"),
      projekt: localStorage.getItem("projekt"),
      risk_categories: localStorage.getItem("risk_categories"),
      risk_sub_categories: localStorage.getItem("risk_sub_categories"),
      sonstige: localStorage.getItem("sonstige"),
      teilnehmer: localStorage.getItem("teilnehmer"),
      token: localStorage.getItem("token"),
    };
    localStorage.clear();
    Object.keys(preStore).forEach((objectName) => {
      localStorage.setItem(objectName, preStore[objectName]);
    });
  };

  useEffect(() => {
    setEventList([]);
    setFormSubmitted(false);
  }, [inspectionType]);

  const properDate = (dateString) => {
    var date = dateString.split(".");
    return date[1] + "." + date[2] + "." + date[0];
  };

  return (
    <>
      {onTop ? (
        <Header requiredHeight={170} logout={true} />
      ) : (
        <Header requiredHeight={60} logout={true} />
      )}
      <div
        className={isPortrait ? "container-fliud" : "container"}
        style={{ padding: 10 }}
      >
        <SeperatorHeading heading={"Angaben zur Begehung"} />
        <InspectionType types={inspectionTypes} />
        <FormField label={"Projekt (optional)"} id={"projekt"} type={"text"} />
        <FormField
          label={"Auftraggeber / Kunde"}
          id={"auftraggeber"}
          type={"text"}
        />
        <FormField label={"Bereich (optional)"} id={"bereich"} type={"text"} />
        <FormField
          label={"Begehungsdatum"}
          id={"begehungsdatum"}
          type={"date"}
        />
        <FormField
          label={"Begehung durch"}
          id={"begehungdurch"}
          type={"text"}
        />
        <FormField label={"Teilnehmer"} id={"teilnehmer"} type={"text"} />
        <FormField
          label={"Sonstige Hinweise (Bemerkungen)"}
          id={"sonstige"}
          type={"text"}
        />
        <TextFieldBig />
        <FormField label={"Email"} id={"email"} type={"email"} />
        <label className="text-muted" style={{ fontSize: 12 }}>
          (Komma verwenden, um weitere E-Mails hinzuzufügen)
        </label>
        {eventList.length > 0 ? (
          <SeperatorHeading heading={"Angaben zum Mangel"} />
        ) : (
          <></>
        )}
        {eventList}

        <div
          className="d-flex justify-content-center"
          style={{ flexDirection: "row" }}
        >
          {eventList.length > 0 && inspectionType !== "Bausstellenbegehung" ? (
            <></>
          ) : (
            <button
              className="btn btn-sifar"
              style={{ marginTop: 10, width: "100%" }}
              onClick={() => {
                onMangelClick();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Mängel
            </button>
          )}

          {eventList.length === 0 ? (
            <></>
          ) : (
            <button
              className="btn btn-sifar-filled shadow"
              style={{
                marginTop: 10,
                marginLeft: inspectionType === "Bausstellenbegehung"?10:0,
                width: "100%",
              }}
              onClick={() => {
                onSubmit();
              }}
            >
              {sendingData ? (
                <>
                  <FontAwesomeIcon icon={faEllipsis} /> Bericht erstellen
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faUpload} /> Abschließen
                </>
              )}
            </button>
          )}
        </div>
        {formSubmitted ? (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <button
              className="btn btn-success shadow"
              style={{ width: "100%" }}
              onClick={() => savePdf()}
            >
              <FontAwesomeIcon icon={faDownload} /> Bericht herunterladen
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Form;
