import React, { useEffect, useState } from "react";

const Dropdown = ({
  id,
  title,
  options,
  valueChange,
  setValueChange,
  customClassName,
  changeListener,
}) => {
  const [toggled, setToggled] = useState(false);
  const [value, setValue] = useState("");
  useEffect(() => {
    localStorage.setItem(id, JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    document.getElementById(id).selectedIndex = 0;
    setToggled(false);
    localStorage.setItem(id, JSON.stringify(""));
  }, [changeListener]);
  return (
    <div style={{ marginTop: 10 }}>
      <select
        id={id}
        className={"form-control" + " " + customClassName}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        onChange={(e) => {
          setToggled(true);
          setValue(e.currentTarget.value);
          setValueChange(!valueChange);
        }}
      >
        <option selected disabled>
          {title}
        </option>
        {options.map((opt, key) => {
          return <option value={opt.value}>{opt.title}</option>;
        })}
      </select>
      {toggled ? (
        <label className="text-muted" style={{ fontSize: 12 }}>
          {title}
        </label>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dropdown;
