import {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faDotCircle,
  faQuestionCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import FormField from "../FormField/FormField";
import demoQuestions from "./demoQuestions";
import QuestionComponent from "./QuestionComponent";

const CompanyIncident = ({ index }) => {
  const [questions, setQuestions] = useState(
    JSON.parse(localStorage.getItem("incident_questions")).filter(
      (_q) =>
        _q.inspection_type_id ===
        JSON.parse(localStorage.getItem("inspektionsart"))
    )
  );
  const [components, setComponents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    questions.forEach((question) => {
      setComponents((components) => [
        ...components,
        <QuestionComponent
          question={question}
          key={question.id}
          index={components.length}
        />,
      ]);
    });
  }, []);

  const numberOfQuestionsStyle = () => ({
    height: 40,
    width: "100%",
    padding: 10,

    borderTopLeftRadius:
      currentIndex === components.length - 1
        ? 0
        : currentIndex === 0
        ? 5
        : false,
    borderBottomLeftRadius:
      currentIndex === components.length - 1
        ? 0
        : currentIndex === 0
        ? 5
        : false,

    borderTopRightRadius:
      currentIndex === 0
        ? 0
        : currentIndex === components.length - 1
        ? 5
        : false,
    borderBottomRightRadius:
      currentIndex === 0
        ? 0
        : currentIndex === components.length - 1
        ? 5
        : false,

    border: "1px solid #332885",
    color: "white",
    backgroundColor: "#332885",
    transition: "0.2s",
  });

  return (
    <div className="shadow" style={{marginTop:10, marginBottom:20}}>
      <div style={{ padding: 10 }}>
        {components[currentIndex]}
      </div>
      <div className="d-flex justify-content-between" style={{marginTop:10}}>
        {currentIndex !== 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              ...nav_button,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
            onClick={() => setCurrentIndex((currentIndex) => currentIndex - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        ) : (
          <></>
        )}
        <div
          className="d-flex justify-content-center align-items-center"
          style={numberOfQuestionsStyle()}
        >
          Question: {currentIndex + 1}/{questions.length}
        </div>
        {currentIndex !== components.length - 1 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              ...nav_button,
              marginLeft: currentIndex === 0 ? "auto" : false,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
            onClick={() => setCurrentIndex((currentIndex) => currentIndex + 1)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CompanyIncident;

const nav_button = {
  backgroundColor: "white",
  border: "1px solid #332885",
  color: "#332885",
  height: 40,
  width: 40,
};
