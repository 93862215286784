import React, { useEffect, useState } from "react";
import CheckBox from "./CheckBox";

const CheckColor = ({ index }) => {
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <label>Einstufung</label>

      <CheckBox
        title={
          "Sehr schwerer Mangel. Gefahr im Verzug. Die Arbeiten werden in Abstimmung mit einem Verantwortlichen der Baustelle gestoppt."
        }
        index={index}
        colors={["#990000"]}
      />
      <hr />
      <CheckBox
        title={
          "Schwerer Mangel. Es sind schnellstmöglich Maßnahmen zu ergreifen."
        }
        index={index}
        colors={["#ff0000"]}
      />
      <hr />

      <CheckBox
        title={"Mangel. Es sind zeitnah Maßnahmen zu ergreifen."}
        index={index}
        colors={["#fa6912"]}
      />
      <hr />

      <CheckBox
        title={"Sehr schwerer Mangel. Wurde vor Ort behoben."}
        index={index}
        colors={["#990000", "#dfdf00"]}
      />
      <hr />

      <CheckBox
        title={"Schwerer Mangel. Wurde vor Ort behoben."}
        index={index}
        colors={["#ff0000", "#dfdf00"]}
      />
      <hr />

      <CheckBox
        title={"Mangel. Wurde vor Ort behoben."}
        index={index}
        colors={["#fa6912", "#dfdf00"]}
      />
      <hr />

      <CheckBox
        title={"Vorbildlicher Zustand."}
        index={index}
        colors={["#157515"]}
      />
    <hr />

      <CheckBox title={"Hinweis."} index={index} colors={["#ffffff"]} />
    </div>
  );
};

export default CheckColor;
