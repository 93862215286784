import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inspectionType: "",
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setInspectionType(state, action) {
      state.inspectionType = action.payload;
    },
  },
});

export const {setInspectionType} = formSlice.actions;
export default formSlice.reducer;
