import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInspectionType } from "../../../pages/Form/formSlice";

const InspectionType = ({ types }) => {
  const [value, setValue] = useState();
  const [valueName, setValueName] = useState();
  const [resetButtonVisible, setResetButtonVisible] = useState(false);
  const inspectionType = useSelector((state) => state.form.inspectionType);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("val changed", value);
    localStorage.setItem("inspektionsart", JSON.stringify(value));
    dispatch(setInspectionType(valueName));
  }, [value]);

  useEffect(() => {
    if (value !== "") {
      setResetButtonVisible(true);
    } else {
      setResetButtonVisible(false);
    }
  }, [value]);
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <label>{"Art der Begehung"}</label>
      {types.map((type, key) => {
        return (
          <div className="form-check" key={key}>
            <input
              className="form-check-input"
              type="radio"
              id={type.name}
              value={type.id}
              name={"inspektionsart"}
              onChange={(e) => {
                setValue(e.target.value);
                setValueName(e.target.id);
              }}
            />
            <label htmlFor={type.name} className="form-check-label">
              {type.name}
            </label>
          </div>
        );
      })}

      {/* <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="Betriebsbegehung"
          value={"Betriebsbegehung"}
          name={"inspektionsart"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label htmlFor="Betriebsbegehung" className="form-check-label">
          Betriebsbegehung
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="Brandschutzbegehung"
          value={"Brandschutzbegehung"}
          name={"inspektionsart"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label htmlFor="Brandschutzbegehung" className="form-check-label">
          Brandschutzbegehung
        </label>
      </div> */}
    </div>
  );
};

export default InspectionType;
