import React, { useEffect, useState } from "react";
import FormField from "./FormField";
import CheckColor from "./CheckColor";
import PhotoHolder from "./PhotoHolder";
import EventHeader from "./EventHeader";
import YesNoOrField from "./YesNoOrField";
import Dropdown from "./Dropdown";

const Events = ({ index }) => {
  const [riskChange, setRiskChange] = useState(false);
  const [protectiveMeasure, setProtectiveMeasure] = useState([]);
  const [risks, setRisks] = useState([]);
  const [risksSubheading, setRisksSubheading] = useState([]);
  const risk_categories = JSON.parse(localStorage.getItem("risk_categories"));
  const risk_sub_categories = JSON.parse(
    localStorage.getItem("risk_sub_categories")
  );

  useEffect(() => {
    let gefahrdung = JSON.parse(localStorage.getItem("gefahrdung" + index));
    console.log("gefahrdung:", gefahrdung);
    if (gefahrdung !== "") {
      let _risksSubheading = [];
      risk_sub_categories.forEach((sub) => {
        if (sub.risk_category_id === gefahrdung) {
          _risksSubheading.push({
            title: sub.name,
            value: sub.id,
          });
        }
      });
      setRisksSubheading(_risksSubheading);
    }
  }, [riskChange]);

  useEffect(() => {
    let _risks = [];
    risk_categories.forEach((risk) => {
      _risks.push({
        title: risk.name,
        value: risk.id,
      });
    });
    setRisks(_risks);
  }, []);

  return (
    <div
      id={"event" + index}
      className="shadow"
      style={{
        marginTop: 10,
        marginBottom: 40,
        borderRadius: 10,
        padding: 10,
        overflow: "hidden",
      }}
    >
      <EventHeader index={index} />

      <div id={"eventholder" + index}>
        {/* <FormField
          label={"Gefährdung"}
          id={"gefahrdung" + index}
          type={"text"}
        /> */}

        <Dropdown
          id={"gefahrdung" + index}
          title="Gefährdung"
          options={risks}
          valueChange={riskChange}
          setValueChange={setRiskChange}
        />

        {risksSubheading.length > 0 ? (
          <Dropdown
            id={"gefahrdung_unterkategorie" + index}
            title="Gefährdung Unterkategorie"
            options={risksSubheading}
          />
        ) : (
          <></>
        )}

        <FormField
          label={"Festgestellter Mangel"}
          id={"festgestellter_mangel" + index}
          type={"text"}
        />
        {/* <FormField
          label={"Gewerksübergreifend"}
          id={"gewerksübergreifend" + index}
          type={"text"}
        /> */}
        <YesNoOrField index={index} />
        <FormField label={"Ort"} id={"ort" + index} type={"text"} />
        <FormField
          label={"Verursacht durch"}
          id={"verursachtdurch" + index}
          type={"text"}
        />
        <FormField label={"Gewerk"} id={"gewerk" + index} type={"text"} />
        <FormField
          label={"Schutzmaßnahme"}
          id={"schutzmassnahme" + index}
          type={"text"}
        />
        {/* <Dropdown
          id={"schutzmassnahme" + index}
          title="Schutzmaßnahme"
          options={protectiveMeasure}
          changeListener={riskChange}
        /> */}
        <CheckColor index={index} />
        <div
          className="card"
          style={{
            marginTop: 10,
            padding: 10,
          }}
        >
          <PhotoHolder index={index} />
        </div>
      </div>
    </div>
  );
};

export default Events;
