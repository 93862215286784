import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const CheckBox = ({ title, index, colors, setValue, value }) => {
  const uniqueRadio = uuidv4();
  return (
    <div className="form-check" style={checkBoxContainer}>
      <input
        onChange={(e) => setValue(e.target.value)}
        className="form-check-input"
        type="radio"
        name={"radio" + index}
        id={"radio" + uniqueRadio}
        value={colors.join()}
        style={{ minHeight: 16, minWidth: 16 }}
        checked={value === colors.join() ? true : false}
      />
      <div style={colorContainer}>
        {colors.map((color, key) => (
          <div key={key} style={{ backgroundColor: color, height: 30 }}></div>
        ))}
      </div>
      <label className="form-check-label" htmlFor={"radio" + uniqueRadio}>
        {title}
      </label>
    </div>
  );
};

export default CheckBox;

const colorContainer = {
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  flexDirection: "column",
  borderRadius: 5,
  border: "1px solid black",
  height: 30,
  minWidth: 30,
  overflow: "hidden",
};

const checkBoxContainer = {
  display: "flex",
  paddingTop: 10,
  paddingBottom: 10,
};
