import React, { useEffect, useState } from "react";

const YesNoOrField = ({ index }) => {
  const [value, setValue] = useState("");
  const [resetButtonVisible, setResetButtonVisible] = useState(false);
  useEffect(() => {
    localStorage.setItem("gewerksübergreifend" + index, JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    if (value !== "") {
      setResetButtonVisible(true);
    } else {
      setResetButtonVisible(false);
    }
  }, [value]);
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <label>{"Gewerksübergreifend"}</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={"Ja"}
          name={"gewerksübergreifend" + index}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label className="form-check-label">Ja</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={"Nein"}
          name={"gewerksübergreifend" + index}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label className="form-check-label">Nein</label>
      </div>

      {resetButtonVisible ? (
        <>
          <hr />
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              setValue("");
              document.querySelector(
                `input[name=${"gewerksübergreifend" + index}]:checked`
              ).checked = false;
            }}
          >
            Auswahl löschen
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default YesNoOrField;
