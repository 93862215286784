import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const CheckBox = ({ title, index, colors }) => {
  const [value, setValue] = useState("");
  const uniqueRadio = uuidv4();
  useEffect(() => {
    localStorage.setItem("einstufung" + index, JSON.stringify(value));
  }, [value]);
  return (
    <div
      className="form-check"
      style={{
        display: "flex",
        paddingTop: 10,
        paddingBottom: 10,
        // marginTop: 5,
        // border: "1px solid #E5E5E5",
        // borderRadius: 5,
      }}
    >
      <input
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="form-check-input"
        type="radio"
        name={"radio" + index}
        id={"radio" + uniqueRadio}
        value={colors.join()}
        style={{ minHeight: 16, minWidth: 16 }}
      />
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          display: "flex",
          flexDirection: "column",
          borderRadius: 5,
          border: "1px solid black",
          height: 30,
          minWidth: 30,
          overflow: "hidden",
        }}
      >
        {colors.map((color, key) => {
          return (
            <div key={key} style={{ backgroundColor: color, height: 30 }}></div>
          );
        })}
      </div>
      <label className="form-check-label" htmlFor={"radio" + uniqueRadio}>
        {title}
      </label>
    </div>
  );
};

export default CheckBox;
