import React, { useState, useRef, useEffect } from "react";
import { Camera } from "react-camera-pro";
const CameraView = ({ closeModal, setToggleCapture, index }) => {
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");

  return (
    <div className="row">
      <div
        className="col d-flex justify-content-center"
        style={{ height: "90vh", alignItems: "flex-end" }}
      >
        <div>
          <Camera ref={camera} facingMode={facingMode} />
        </div>

        <button
          className="btn btn-outline-info"
          style={{
            zIndex: 999,
            height: 50,
          }}
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>

        <button
          className="btn btn-primary"
          style={{
            zIndex: 999,
            height: 50,
            marginLeft: 10,
          }}
          onClick={() => {
            var photos = [];
            if (localStorage.getItem("photos" + index)) {
              photos = JSON.parse(localStorage.getItem("photos" + index));
            } else {
              localStorage.setItem("photos" + index, JSON.stringify(photos));
              photos = JSON.parse(localStorage.getItem("photos" + index));
            }
            //  photos[index] = photos[index] || [index];
            const photoObject = {
              description: "",
              photo: camera.current.takePhoto(),
            };
            photos.push(photoObject);
            localStorage.setItem("photos" + index, JSON.stringify(photos));
            closeModal();
            setToggleCapture(true);
            setImage(camera.current.takePhoto());
          }}
        >
          <i className="fa-solid fa-camera"></i>
        </button>

        <button
          className="btn btn-outline-light"
          style={{
            zIndex: 999,
            height: 50,
            marginLeft: 10,
          }}
          onClick={() => {
            camera.current.switchCamera();
          }}
        >
          <i className="fa-solid fa-camera-rotate"></i>
        </button>
      </div>
    </div>
  );
};

export default CameraView;
