import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import back from "../../assets/images/back.png";
import header from "../../assets/images/header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Header = ({ requiredHeight = 170, logout = false }) => {
  const [opacityVal, setOpacityVal] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  return (
    <nav
      id="navbar"
      className={requiredHeight === 170 ? "navbar" : "navbar sticky-top"}
      style={{
        height: requiredHeight,
        backgroundImage:
          requiredHeight === 170 ? `url(${back})` : `url(${header})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundColor:
          requiredHeight === 170 ? "transparent" : "rgba(255, 255, 255, 0.8)",
        boxShadow:
          requiredHeight === 170 ? "" : "0 4px 30px rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(6.5px)",
        WebkitBackdropFilter: "blur(6.5px)",
        transition: "0.5s",
        border:
          requiredHeight === 170 ? "" : "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div className="container-fluid d-flex justify-content-center">
        <div className="navbar-brand" style={{ position: "absolute" }}>
          <img
            id="logo"
            src={logo}
            style={{
              height: requiredHeight > 60 ? 30 : 20,
              transition: "height 1.3s",
            }}
            // height={requiredHeight > 60 ? 30 : 20}
            className="d-inline-block align-text-top"
          />
        </div>
        <div style={{ position: "absolute", right: 10 }}>
          {logout ? (
            <div
              className="logoutButton d-flex justify-content-center align-items-center"
              style={{
                height: requiredHeight > 60 ? 45 : 40,
                width: requiredHeight > 60 ? 45 : 40,
                transition: "0.3s",
                border: requiredHeight > 60 ? false : '1px solid rgba(255, 255, 255, 0.1)',
              }}
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
