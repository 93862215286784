import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CameraView from "./CameraView";
import Resizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const AddPhoto = ({ setToggleCapture, index }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCameraNeeded, setIsCameraNeeded] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setModalIsOpen(false);
    setIsCameraNeeded(false);
  }

  const getFile = (e) => {
    setUploadingFile(true);
    var file = e.target.files[0];
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      70,
      0,
      (uri) => {
        storeImage(uri);
        setUploadingFile(false);
      },
      "base64"
    );
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const storeImage = (image) => {
    var photos = [];
    if (localStorage.getItem("photos" + index)) {
      photos = JSON.parse(localStorage.getItem("photos" + index));
    } else {
      localStorage.setItem("photos" + index, JSON.stringify(photos));
      photos = JSON.parse(localStorage.getItem("photos" + index));
    }
    const photoObject = {
      description: "",
      // photo: image,
      decoded_image: image,
    };
    photos.push(photoObject);
    localStorage.setItem("photos" + index, JSON.stringify(photos));
    closeModal();
    setToggleCapture(true);
  };

  return (
    <>
      <label
        // onClick={() => {
        //   openModal();
        // }}
        className="col-3"
        style={{
          border: "2px dotted black",
          marginTop: 5,
          height: 200,
        }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            getFile(e);
          }}
        />

        <div
          className="d-flex justify-content-center"
          style={{
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <FontAwesomeIcon icon={faCamera}/>
        </div>
      </label>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {uploadingFile ? <div>Uploading</div> : <></>}
          {isCameraNeeded ? (
            <CameraView
              closeModal={closeModal}
              setToggleCapture={setToggleCapture}
              index={index}
            />
          ) : (
            <div className="d-flex justify-content-center flex-column">
              <label className="btn btn-light">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    getFile(e);
                  }}
                />
                <i className="fa-solid fa-file-image"></i> Von Fotos auswählen
              </label>
              <div className="mt-2"></div>
              <div
                className="btn btn-light"
                onClick={() => setIsCameraNeeded(true)}
              >
                <i className="fa-solid fa-camera"></i> Foto aufnehmen
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  },
};

export default AddPhoto;
