import React from 'react'

const SeperatorHeading = ({ heading }) => {
    return (
      <figure className="text-center" style={{ marginTop: 20 }}>
        <blockquote className="blockquote">
          <p>{heading}</p>
        </blockquote>
        <figcaption className="blockquote-footer"></figcaption>
      </figure>
    );
  };

export default SeperatorHeading
