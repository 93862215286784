import {
  faAngleDown,
  faAngleRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const EventHeader = ({ index }) => {
  const eventArray = [
    "gefahrdung",
    "festgestellter_mangel",
    "gewerksübergreifend",
    "ort",
    "verursachtdurch",
    "gewerk",
    "schutzmassnahme",
    "einstufung",
  ];
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (toggle) {
      fadeOutEffect();
    } else {
      fadeInEffect();
    }
  }, [toggle]);

  function fadeOutEffect() {
    var fadeTarget = document.getElementById("eventholder" + index);
    var fadeEffect = setInterval(function () {
      if (!fadeTarget.style.opacity) {
        fadeTarget.style.opacity = 1;
      }
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.4;
      } else {
        clearInterval(fadeEffect);
        fadeTarget.style.display = "none";
      }
    }, 50);
  }

  function fadeInEffect() {
    var fadeTarget = document.getElementById("eventholder" + index);
    fadeTarget.style.display = "block";
    fadeTarget.style.opacity = 1;
  }
  return (
    <>
      <div
        style={{
          height: 60,
          margin: -10,
          padding: 10,
        }}
      >
        <div className="row">
          <div className="col d-flex flex-row align-items-center">
            <button
              onClick={() => setToggle(!toggle)}
              className="btn"
              style={{ border: "none", color: "#332785" }}
            >
              {toggle ? (
                <FontAwesomeIcon icon={faAngleRight} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </button>
          </div>
          <div
            onClick={() => setToggle(!toggle)}
            className="col d-flex justify-content-center align-items-center"
          >
            <label id={"eventTitle" + index}></label>
          </div>
          <div className="col d-flex justify-content-end">
            <button
              className="btn btn-light"
              onClick={() => {
                document.getElementById("event" + index).innerHTML = "";
                document.getElementById("event" + index).className = "";
                document.getElementById("event" + index).style = "";
                // document.getElementById("event" + index).remove();
                for (let x = 0; x < eventArray.length; x++) {
                  localStorage.removeItem(eventArray[x] + index);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventHeader;
