import React, { useEffect, useState } from 'react'

const TextFieldBig = () => {
    const [value, setValue] = useState("");
    useEffect(() => {
      localStorage.setItem("anmerkung", JSON.stringify(value));
    }, [value]);
    return (
      <div className="card" style={{ marginTop: 10 }}>
        <label style={{ padding: 10 }} htmlFor={"anmerkung"}>
          {"Anmerkung zur Begehung"}
        </label>
        <textarea
          className="form-control"
          style={{ border: "none" }}
          id={"anmerkung"}
          placeholder={""}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          rows="3"
        ></textarea>
      </div>
    );
  };

export default TextFieldBig
