import { useState, useEffect } from "react";
import CheckColor from "./CheckColor";
import PhotoHolder from "./PhotoHolder";

function QuestionComponent(props) {
  const { question, index } = props;
  const id = question.id;

  return (
    <div>
      <label style={{ marginTop: 10 }}>
        <b>
          {index + 1}
          {": "}
        </b>
        {question.question}
      </label>
      <YesNoOrField question={question} id={id} index={index} key={index} />
    </div>
  );
}

export default QuestionComponent;

const YesNoOrField = ({ question, id, index }) => {
  const [value, setValue] = useState(localStorage.getItem("yn_" + index));
  const [comment, setComment] = useState(
    localStorage.getItem("yn_comment_" + index)
  );
  const [resetButtonVisible, setResetButtonVisible] = useState(false);
  useEffect(() => {
    localStorage.setItem("yn_" + index, value);
  }, [value]);

  useEffect(() => {
    if (value !== "") {
      setResetButtonVisible(true);
    } else {
      setResetButtonVisible(false);
    }
  }, [value]);
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={"Ja"}
          name={"yn_" + index}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          checked={value === "Ja" ? true : false}
        />
        <label className="form-check-label">Ja</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={"Nein"}
          name={"yn_" + index}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          checked={value === "Nein" ? true : false}
        />
        <label className="form-check-label">Nein</label>
      </div>

      {value === "Nein" ? (
        <>
          <FormField id={"yn_comment_" + index} label={"Grund"} type={"text"} />
          <CheckColor index={index} />
          <PhotoHolder index={index} />
        </>
      ) : (
        <></>
      )}

      {resetButtonVisible ? (
        <>
          <hr />
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              setValue("");
              localStorage.removeItem("photos" + index);
            }}
          >
            Auswahl löschen
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const FormField = ({ label, id, type, icon }) => {
  const [value, setValue] = useState(localStorage.getItem(id));
  useEffect(() => {
    localStorage.setItem(id, value);
  }, [value]);

  return (
    <div className="form-floating" style={{ marginTop: 10 }}>
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={label}
        value={value != "null" ? value : ""}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <label htmlFor={id}>
        <i className={icon}></i> {label}
      </label>
    </div>
  );
};
