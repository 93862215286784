import { faArrowLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

const Gallery = ({ photo, index, closeModal, albumKey, setToggleCapture }) => {
  const [value, setValue] = useState("");
  const deleteHandler = () => {
    const photos = JSON.parse(localStorage.getItem("photos" + index));
    photos.splice(albumKey, 1);
    localStorage.setItem("photos" + index, JSON.stringify(photos));
    setToggleCapture(true);
    closeModal();
  };

  useEffect(() => {
    const photos = JSON.parse(localStorage.getItem("photos" + index));
    setValue(photos[albumKey].description);
  }, []);

  useEffect(() => {
    const photos = JSON.parse(localStorage.getItem("photos" + index));
    photos[albumKey].description = value;
    localStorage.setItem("photos" + index, JSON.stringify(photos));
  }, [value]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        className="d-flex justify-content-center"
        style={{
          position: "absolute",
          width: "100%",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            marginTop: "65vh",
            width: "100%",
            flexDirection: "column",
            padding: 5,
          }}
        >
          <div className="d-flex justify-content-center">
            <button
              style={{
                height: 50,
              }}
              className="btn btn-outline-info"
              onClick={() => {
                closeModal();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              style={{
                height: 50,
                marginLeft: 10,
              }}
              className="btn btn-danger"
              onClick={() => deleteHandler()}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 10, width: "100%" }}
          >
            <div className="form-floating" style={{ width: "100%" }}>
              <input
                type={"text"}
                className="form-control"
                placeholder={"Beschreibung"}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
              <label>{"Beschreibung"}</label>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img src={photo.decoded_image} style={{ width: "100%" }} alt="" />
      </div>
    </div>
  );
};

export default Gallery;
