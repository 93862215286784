export default [
  {
    title:
      "Sehr schwerer Mangel. Gefahr im Verzug. Die Arbeiten werden in Abstimmung mit einem Verantwortlichen der Baustelle gestoppt.",
    colors: ["#990000"],
  },
  {
    title: "Schwerer Mangel. Es sind schnellstmöglich Maßnahmen zu ergreifen.",
    colors: ["#ff0000"],
  },
  {
    title: "Mangel. Es sind zeitnah Maßnahmen zu ergreifen.",
    colors: ["#fa6912"],
  },
  {
    title: "Vorbildlicher Zustand.",
    colors: ["#157515"],
  },
];
