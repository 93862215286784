import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Login from "./pages/Login/Login";
import Form from "./pages/Form/Form";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Form />} />
      <Route exact path="/form" element={<Form />} />
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
