import React, { useEffect, useState } from "react";
import CheckBox from "./CheckBox";
import checkBoxData from "./checkBoxData";

const CheckColor = ({ index }) => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem("status_" + index))
  );
  useEffect(() => {
    localStorage.setItem("status_" + index, JSON.stringify(value));
  }, [value]);
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <label>Einstufung</label>
      <hr />
      {checkBoxData.map((checkBox, key) => (
        <>
          <CheckBox
            title={checkBox.title}
            index={index}
            colors={checkBox.colors}
            value={value}
            setValue={setValue}
          />
          <hr />
        </>
      ))}
    </div>
  );
};

export default CheckColor;
